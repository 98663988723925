<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <!-- <h1>ข้อตกลงความเป็นส่วนตัว</h1> -->
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/maritimebankassetmanagement/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`Chính sách này chỉ áp dụng cho các sản phẩm và dịch vụ được cung cấp bởi [MARITIME BANK ASSET MANAGEMENT COMPANY LIMITED] và các chi nhánh của nó (sau đây gọi là "chúng tôi" hoặc "[Công ty]") và các chức năng mở rộng của chúng (sau đây gọi là "[Dịch vụ]"), bao gồm Mar Vay APP. Nếu sản phẩm chúng tôi cung cấp có chính sách bảo mật riêng biệt hoặc thỏa thuận đặc biệt trong thỏa thuận dịch vụ người dùng tương ứng, thì chính sách bảo mật của sản phẩm sẽ được ưu tiên. Đối với nội dung không có trong chính sách bảo mật của sản phẩm và thỏa thuận dịch vụ người dùng, chính sách này nội dung sẽ được ưu tiên.
Về khoản vay:
-Hạn mức tín dụng: lên tới 26.000.000 VNĐ
Thời gian: 93 đến 365 ngày
Lãi suất (APR): Lãi suất hàng năm lên tới 30%/năm
-Thuế GTGT: 0
Hoa hồng: 0
Ví dụ: đối với khoản vay 200.000 đồng được hoàn trả trong vòng 120 ngày, bạn chỉ phải trả 19.726 đồng (200.000*30%*120/365 = 19.726) tiền lãi và tổng số tiền còn nợ là 219.726 đồng.
Mọi thắc mắc, ý kiến đóng góp, bạn có thể liên hệ với chúng tôi qua các hình thức liên hệ sau:
Số điện thoại liên hệ: +84 580203940
Địa chỉ: 54A Nguyen Chi Thanh, Lang Thuong Ward, Floor 24, Ha Noi
Giới thiệu
Chúng tôi rất chú trọng đến quyền riêng tư của người dùng và bảo vệ thông tin cá nhân. Khi bạn sử dụng các sản phẩm và / hoặc dịch vụ của chúng tôi, chúng tôi có thể thu thập và sử dụng thông tin của bạn. Chúng tôi hy vọng thông qua việc sử dụng "Chính sách Bảo mật" ("Chính sách") để giải thích cho bạn về tình hình kinh doanh của chúng tôi và các loại thông tin cá nhân cần được thu thập từ bạn, cũng như cung cấp cho bạn quyền truy cập, cập nhật và xóa quyền truy cập vào thông tin này và các biện pháp bảo vệ, cũng như khi các quy tắc mà chúng tôi cần tuân theo khi chúng tôi chia sẻ, chuyển giao và tiết lộ công khai thông tin cá nhân của bạn. Chính sách này có liên quan chặt chẽ đến dịch vụ bạn sử dụng và các chức năng kinh doanh khác nhau có trong dịch vụ (sau đây được gọi chung là "sản phẩm và / hoặc dịch vụ của chúng tôi"). Tôi hy vọng bạn đọc kỹ và xác nhận bạn trước khi sử dụng sản phẩm và / hoặc đã hiểu tất cả nội dung của chính sách này (đặc biệt là nội dung bằng phông chữ đậm) và cho phép bạn đưa ra các lựa chọn mà bạn cho là phù hợp theo các hướng dẫn của chính sách này.
Điều quan trọng cần lưu ý là chính sách này chỉ áp dụng cho các sản phẩm và / hoặc dịch vụ được xác định trong chính sách này. Khi bạn sử dụng sản phẩm hoặc dịch vụ do tổ chức bên thứ ba trực tiếp cung cấp và cung cấp thông tin cá nhân của bạn, chúng tôi khuyên bạn nên xem xét kỹ chính sách bảo mật của họ trước khi sử dụng để hiểu cách họ xử lý thông tin của bạn để đưa ra quyết định thận trọng.
Chúng tôi sẽ tuân theo các nguyên tắc công bằng, hợp pháp và cần thiết, và vì các mục đích sau được mô tả trong chính sách này, thu thập và sử dụng thông tin cá nhân mà bạn chủ động cung cấp trong quá trình sử dụng sản phẩm và / hoặc dịch vụ. Và lấy thông tin cá nhân có liên quan của bạn từ các bên thứ ba. Nếu chúng tôi muốn sử dụng thông tin cá nhân của bạn cho các mục đích khác không được quy định trong chính sách này, hoặc sử dụng thông tin thu thập được cho một mục đích cụ thể cho các mục đích khác, chúng tôi sẽ thông báo cho bạn một cách hợp lý và yêu cầu cung cấp lại trước khi sử dụng.
1 Cách chúng tôi thu thập thông tin cá nhân của bạn
Chúng tôi sẽ lấy thông tin cá nhân của bạn thông qua ba kênh sau:
1.1.1 Thông tin bạn cung cấp trực tiếp: Chúng tôi có thể thu thập và lưu trữ thông tin bạn cung cấp cho chúng tôi khi bạn sử dụng [dịch vụ], kể cả khi bạn điền thông tin trực tuyến trên trang của chúng tôi, khi bạn đăng ký sản phẩm và / hoặc dịch vụ, khi bạn tham gia trực tuyến thảo luận cộng đồng hoặc cung cấp khi liên hệ với chúng tôi về [dịch vụ], cũng như thông tin về các giao dịch và hoạt động của bạn được tạo ra trong quá trình bạn sử dụng dịch vụ của chúng tôi.
1.1.2 Thông tin chúng tôi chủ động thu thập: Khi bạn sử dụng trang web của [Công ty], APP và các dịch vụ liên quan, chúng tôi sẽ thu thập thông tin được gửi cho chúng tôi bằng máy tính, điện thoại di động và các thiết bị truy cập khác của bạn.
1.1.3 Thông tin chúng tôi lấy từ các nguồn khác: Tùy thuộc vào sự đồng ý của bạn, chúng tôi cũng có thể lấy thông tin liên quan của bạn từ các chi nhánh và đối tác của [Công ty], các tổ chức tín dụng và tổ chức bên thứ ba được thành lập hợp pháp và lưu giữ hợp pháp thông tin liên quan của bạn.
1.2 Chúng tôi thu thập các thông tin nào về bạn
Trong quá trình thực hiện các chức năng kinh doanh sau đây của [Công ty], chúng tôi cần thu thập một số thông tin cá nhân của bạn để cung cấp cho bạn dịch vụ, cải thiện chất lượng dịch vụ của chúng tôi, đảm bảo an toàn cho chủ tài khoản và tiền của bạn, đồng thời tuân thủ luật pháp, quy định quốc gia. Xin lưu ý rằng nếu bạn cung cấp thông tin cá nhân của người khác, hãy đảm bảo rằng bạn có sự cho phép của chủ thể có liên quan.

 1.2.1 Thông tin nhận dạng (chẳng hạn như: tên, nơi sinh, ngày tháng năm sinh, ảnh đại diện khuôn mặt, v.v.);
1.2.2 Thông tin cá nhân liên quan đến dịch vụ cho vay (chẳng hạn như: thông tin hoặc tài liệu việc làm, trình độ học vấn, v.v.);
1.2.3 Thông tin và tài liệu nhận dạng do chính phủ cấp (chẳng hạn như giấy chứng nhận CMND/CCCD, hộ chiếu, giấy phép lái xe, v.v.);
1.2.4 Dữ liệu thiết bị di động (chẳng hạn như SIM, IMEI, vị trí dựa trên địa chỉ giao thức Internet ("server"), lịch sử và sử dụng ứng dụng hoặc các số nhận dạng thiết bị khác, loại thiết bị);
1.2.5 Đọc và ghi bộ nhớ ngoài;
1.2.6 Thông tin liên lạc. Chúng tôi sẽ tải lên và chuyển dữ liệu danh sách liên hệ của bạn (bao gồm tên và số điện thoại) từ điện thoại di động của bạn sang ([server]). Tên và số điện thoại được tải lên và truyền đi sẽ được sử dụng để quản lý rủi ro và kiểm tra chống gian lận. Khi sử dụng ứng dụng, nó định kỳ gửi dữ liệu danh sách liên hệ đến máy chủ của chúng tôi. Chúng tôi sẽ không bao giờ chia sẻ dữ liệu với bên thứ ba mà không có sự cho phép của bạn;
1.2.7 Lưu trữ. Đảm bảo rằng bảng sao kê tài khoản vay của người dùng được tải xuống và lưu một cách an toàn trên điện thoại di động của người dùng; cho phép người dùng tải lên các tệp thích hợp để phê duyệt và thanh toán vay nhanh hơn; cho phép người dùng tận hưởng trải nghiệm rất mượt mà và liền mạch khi sử dụng ứng dụng;
1.2.8 Vị trí gần đúng. Giúp định vị và xác minh địa chỉ, điều này sẽ giúp đưa ra các quyết định rủi ro tín dụng tốt hơn và đẩy nhanh quá trình KYC (hiểu rõ khách hàng của bạn);
1.2.9 Máy ảnh. Cung cấp cho người dùng một cách tốt hơn và đơn giản hơn để quét hoặc thu thập tài liệu v.v., thay vì kiểm tra thủ công tiết kiệm rất nhiều thời gian và giúp cải thiện trải nghiệm người dùng;
1.2.10 Tính trạng điện thoại. Để phát hiện gian lận và quản lý rủi ro tốt hơn, hãy thu thập và theo dõi thông tin cụ thể về điện thoại của bạn, chẳng hạn như ID thiết bị duy nhất của điện thoại và thông tin hồ sơ người dùng. Điều này là cần thiết vì nó giúp chúng tôi nhận dạng duy nhất người dùng và ngăn chặn bất kỳ thiết bị trái phép nào hoạt động thay mặt cho bạn;
1.2.11 Danh sách APP đã cài đặt. Nhận dạng danh sách APP đã cài đặt để đánh giá khách hàng có đủ điều kiện vay hay không. Dữ liệu sẽ được mã hóa và tải lên server.
1.2.12 Thư viện ảnh. Để xử lý đơn xin vay tiền của bạn tốt hơn, Mar Vay yêu cầu quyền truy cập vào thư viện thiết bị của bạn để bạn có thể tải lên các tài liệu và ảnh cần thiết. Chúng tôi chỉ truy cập dữ liệu này khi bạn chủ động tải nó lên và bảo vệ nghiêm ngặt quyền riêng tư của bạn. Dữ liệu hình ảnh của bạn được mã hóa và lưu trữ an toàn và chỉ được sử dụng để xem xét và xử lý đơn xin vay tiền của bạn. Chúng tôi hứa không sử dụng dữ liệu thư viện của bạn cho bất kỳ mục đích nào khác cũng như không chia sẻ dữ liệu đó với bên thứ ba. Bạn có thể quản lý hoặc thu hồi quyền này bất kỳ lúc nào trong cài đặt thiết bị của mình;
1.2.13 Dữ liệu giao dịch và thông tin tài chính (chẳng hạn như các khoản vay, chuyển khoản, thanh toán, yêu cầu vay);
1.2.14 Dữ liệu liên quan đến giao tiếp của bạn với Mar Vay (ví dụ: giao tiếp của bạn với Mar Vay qua trò chuyện trong ứng dụng, điện thoại hoặc các kênh khác);
1.2.15 Dữ liệu bạn cung cấp liên quan đến việc tham gia các ưu đãi và khuyến mãi đặc biệt của Mar Vay (ví dụ: mã khuyến mãi, giảm giá và tham gia vào các nhóm chỉ được mời); và
1.2.16 Một số dữ liệu của bên thứ ba ví dụ: thông tin được cung cấp cho hoặc từ các cơ quan hoặc văn phòng tham chiếu tín dụng, cơ quan thu phí bên ngoài, nhà cung cấp mạng di động).
(Gọi chung là "thông tin" của bạn)
2 Mục đích xử lý thông tin cá nhân
Thông tin chúng tôi thu thập sẽ được sử dụng để liên hệ hoặc nhận dạng bạn, bao gồm nhưng không giới hạn:
2.1 Xác minh danh tính và / hoặc thông tin khác mà bạn cung cấp cho chúng tôi;
2.2 Đánh giá tính đủ điều kiện của bạn để sử dụng các sản phẩm hoặc dịch vụ của chúng tôi;
2.3 Hoàn trả khoản vay của bạn thông qua Mar Vay;
2.4 Giải quyết mọi vấn đề mà bạn hoặc khách hàng khác gặp phải khi sử dụng dịch vụ của Mar Vay;
2.5 Cung cấp dịch vụ khách hàng hoặc hỗ trợ, giải quyết tranh chấp và khiếu nại;
2.6 Quản lý hệ thống của chúng tôi, duy trì chất lượng dịch vụ và tổng hợp số liệu thống kê sử dụng chung;
2.7 Phân tích và cải thiện dịch vụ của chúng tôi;
2.8 Phát hiện, chống lại và ngăn chặn gian lận, cố gắng gian lận và / hoặc sử dụng bất hợp pháp các dịch vụ của chúng tôi;
2.9 Tuân thủ luật pháp, quy định và quy tắc hiện hành;
2.10 Giao tiếp với các cơ quan báo cáo tín dụng hoặc cơ quan phòng chống gian lận;
2.11 Gửi cho bạn các thông báo tiếp thị, quảng cáo hoặc các khuyến mại khác nếu bạn không phản đối việc sử dụng thông tin của mình cho các mục đích tiếp thị trực tiếp sau khi có thông báo thích hợp;
3 Sử dụng thông tin cá nhân
3.1 Để thực hiện các nghĩa vụ của chúng tôi đối với bạn do bất kỳ hợp đồng hoặc thỏa thuận nào được ký kết giữa bạn và chúng tôi;
3.2 Thực hiện các nghĩa vụ pháp lý của chúng tôi theo luật hiện hành;
3.3 Cùng với chúng tôi trong việc (1) cung cấp cho bạn tín dụng và / hoặc các sản phẩm hoặc dịch vụ tài chính hoặc kỹ thuật khác, (2) điều hành hoạt động kinh doanh của chúng tôi, (3) tiếp thị dịch vụ của chúng tôi cho bạn và những người khác, và (4) quản lý hệ thống của chúng tôi liên quan đến lợi ích của công ty, và cập nhật hồ sơ của chúng tôi một cách kịp thời;
Với sự đồng ý trước của bạn.
4 An toàn
4.1 Mar Vay đã áp dụng các biện pháp kỹ thuật, tổ chức và vật lý để đảm bảo các giao dịch khoản vay diễn ra suôn sẻ và an toàn.
4.2 Chúng tôi đánh giá cao sự tin tưởng của bạn trong việc cung cấp cho chúng tôi thông tin cá nhân của bạn, vì vậy chúng tôi đang nỗ lực để bảo vệ thông tin đó bằng các phương pháp được chấp nhận về mặt thương mại.
4.3 Không có phương thức truyền tải nào qua Internet hoặc phương thức lưu trữ điện tử là an toàn và đáng tin cậy 100%. Ngoài ra, mặc dù chúng tôi cố gắng đảm bảo tính toàn vẹn và bảo mật của mạng và hệ thống của mình, nhưng chúng tôi không thể đảm bảo rằng các biện pháp bảo mật của chúng tôi sẽ ngăn chặn "tin tặc" bên thứ ba lấy thông tin của bạn một cách hợp pháp. Chúng tôi không đảm bảo hoặc tuyên bố rằng thông tin của bạn sẽ được bảo vệ, bí mật, sử dụng sai hoặc bị thay đổi bởi các bên thứ ba.
Chúng tôi có thể cập nhật chính sách bảo mật của chúng tôi theo thời gian. Do đó, bạn nên kiểm tra trang này thường xuyên để biết bất kỳ thay đổi nào. Chúng tôi sẽ thông báo cho bạn về bất kỳ thay đổi nào bằng cách đăng chính sách bảo mật mới trên trang này.
5 Các thay đổi đối với chính sách bảo mật này
Chúng tôi có thể cập nhật chính sách bảo mật của chúng tôi theo thời gian. Do đó, bạn nên kiểm tra trang này thường xuyên để biết bất kỳ thay đổi nào. Chúng tôi sẽ thông báo cho bạn về bất kỳ thay đổi nào bằng cách đăng chính sách bảo mật mới trên trang này.
liên hệ chúng tôi:
Địa chỉ công ty: 54A Nguyen Chi Thanh, Lang Thuong Ward, Floor 24, Ha Noi
Email: office@maritimebankassetmanagement.com
ĐT: +84 580203940`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: "";
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #3F4842;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  transform: scale(90%);
  margin: -290px auto 0;
  height: 3800px;
  /* box-shadow:0 6px 14px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}
/* .img1{
  margin-top: 50px;
  padding: 0 200px;
} */
</style>